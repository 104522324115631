import React from 'react';
import './App.css';
import NavBar from './navbar/NavBar';
import AboutPage from "./about/AboutPage";
import Skills from "./skills/SkillsPage";
import Portfolio from "./portfolio/PortfolioPage";
import Contact from "./contact/ContactPage";
import ScrollToTop from './ScrollToTop';
import {Link, Route, Routes} from "react-router-dom";

function HomePage() {
    const titlePart1 = "Software".split("").map((char, index) => (
        <span key={index} className="drop-in" style={{animationDelay: `${index * 0.05}s`}}>{char}</span>
    ));

    const titlePart2 = "Developer".split("").map((char, index) => (
        <span key={index + "Software".length} className="drop-in"
              style={{animationDelay: `${(index + "Software".length) * 0.05}s`}}>{char}</span>
    ));
    return (
        <header className="App-header">
            <div className="about-section">
                <div className="about-text">
                    <h1>{titlePart1}<br/>{titlePart2}</h1>
                    <div className="arrow-container">
                        <Link to="/about" className="arrow">→</Link>
                        <div className="arrow-legend">About Me</div>
                    </div>
                </div>
                <div className="image-and-name">
                    <img className="profilPic" src="ProfilPic.png" alt="Profile pic"/>
                    <h2 className="name-fade-in">Paul REZZONICO</h2>
                </div>
            </div>
        </header>);
}

function App() {
    return (<div className="App">
        <NavBar/>
        <ScrollToTop/>
        <Routes>
            <Route exact path="/" element={<HomePage/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route path="/skills" element={<Skills/>}/>
            <Route path='/portfolio' element={<Portfolio/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="*" element={<HomePage/>}/>
        </Routes>
    </div>);
}

export default App;
