import React from 'react';
import './AboutPage.css';
import {Link} from "react-router-dom";

function AboutPage() {
    return (
        <header className="App-header">
            <div className="about-container">

                <div className="text-section">
                    <h1>Hello! I'm Paul REZZONICO,</h1>
                    <h2>a software developer</h2>
                    <h2>based in Limoges, France.</h2>
                        I studied at the IUT du Limousin, where I earned a Bachelor's Degree in Computer Science,
                        specializing in the design, development, and testing of software.
                        I am currently pursuing an Ingenieur degree in engineering school at 3IL in Limoges.
                        I'm currently working at Legrand as a software developer.
                        I'm passionate about coding and I love learning new things.
                </div>
                <div className="image-section">
                    <img src="about-pic.png" alt="Paul REZZONICO"/>
                    <Link to="/skills" className="arrow-link">
                        <div className="arrow-icon">&#x2192;</div>
                        <div className="arrow-legend">My skills</div>
                    </Link>
                </div>
            </div>
        </header>
    );
}

export default AboutPage;
