import React, {useEffect} from 'react';
import skills from './skillsData.js';
import './SkillsPage.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Link} from "react-router-dom";

function SkillsPage() {
    useEffect(() => {
        AOS.init({
            // Vous pouvez ajouter des options ici si nécessaire
            duration: 1500, // Durée de l'animation
            easing: 'ease-in-out', // Type d'animation
            once: false, // Animation seulement une fois
        });
    }, []);
    return (
        <div className="skills-page">
            <h1 className="skills-title">My technicals skills 🛠️</h1>
            <div className="skills-container">
                {skills.map((skillCategory, index) => (
                    <div key={index} className="skill-category">
                        <h2 className="skill-category-title">{skillCategory.category}</h2>
                        <div className="skill-items" data-aos="fade-up">
                            {skillCategory.items.map((item, itemIndex) => (
                                <div key={itemIndex} className="skill-item" data-aos="flip-left" data-aos-duration="1500">
                                    <div className="skill-badge">
                                        <img src={item.image || item.badge} alt={item.name}/>
                                    </div>
                                    <span className="skill-name">{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="call-to-action">
                <p className="cta-text">
                    Finish to review my skills ? Want to see my projects ?
                </p>
                <Link to="/portfolio" className="cta-link">Check my projects</Link>
            </div>
        </div>
    );
}

AOS.init();
export default SkillsPage;
