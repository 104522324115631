import React from 'react';

function ProjectItem({ project }) {
    return (
        <a href={project.path} target="_blank" rel="noopener noreferrer" className="project-item">
            <img src={project.imageUrl} alt={project.title} className="project-image" />
            <div className="project-info">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-description">{project.description}</p>
            </div>
        </a>
    );
}

export default ProjectItem;
