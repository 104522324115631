import React, {useEffect} from 'react';
import ProjectItem from './project/ProjectItem';
import projects from './project.data.js';
import './PortfolioPage.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

function PortfolioPage() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);
    return (
        <div className="portfolio-page">
            <h1 className="portfolio-title">My projects 🚀</h1>
            <div className="project-list">
                {projects.map(project => (
                    <div key={project.id} data-aos="zoom-in">
                        <ProjectItem project={project} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PortfolioPage;
