import React from 'react';
import './ContactPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function ContactPage() {
  return (
    <header className="App-header">
      <h2>Contact-me 📫</h2>
      <ul>
        <li>
          <a href="mailto:paul.rezzo@gmail.com" target="_blank" className="btn" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} /> Email
          </a>
        </li>
        <li>
          <a href="https://discord.com/users/350767436238159874" target="_blank" className="btn" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} /> Discord
          </a>
        </li>
        <li>
          <a href="https://github.com/paul-rezzonico" target="_blank" className="btn" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} /> GitHub
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/paul-rezzonico/" target="_blank" className="btn" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} /> Linkedin
          </a>
        </li>
      </ul>
    </header>
  );
}

export default ContactPage;
