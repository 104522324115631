const skills = [
    {
        category: "Programming Languages",
        items: [
            { name: "Kotlin", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Kotlin_logo_2021.svg/768px-Kotlin_logo_2021.svg.png"},
            { name: "Java", image: "https://upload.wikimedia.org/wikipedia/fr/thumb/2/2e/Java_Logo.svg/483px-Java_Logo.svg.png?20061227215918"},
            { name: "Python", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1200px-Python-logo-notext.svg.png"},
            { name: "C++", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1200px-ISO_C%2B%2B_Logo.svg.png"},
            { name: "Bash", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Bash_Logo_Colored.svg/1200px-Bash_Logo_Colored.svg.png"},
            { name: "PowerShell", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/PowerShell_5.0_icon.png/1200px-PowerShell_5.0_icon.png"},
            { name: "C#", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/C_Sharp_Logo_2023.svg/1024px-C_Sharp_Logo_2023.svg.png"},
            { name: "Xamarin.Forms", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Xamarin-logo.svg/langfr-1920px-Xamarin-logo.svg.png"},
            { name: "Php", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/1200px-PHP-logo.svg.png"},
            { name: "SQL", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Sql_data_base_with_logo.png/1200px-Sql_data_base_with_logo.png"},
            { name: "Rust", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Rust_programming_language_black_logo.svg/1200px-Rust_programming_language_black_logo.svg.png"},
            { name: "haskell", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Haskell-Logo.svg/1200px-Haskell-Logo.svg.png"},
            { name: "JavaScript", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png"},
        ]
    },
    {
        category: "Frameworks and Libraries",
        items: [
            { name: "Spring", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Spring_Framework_Logo_2018.svg/1200px-Spring_Framework_Logo_2018.svg.png" },
            { name: "Ktor", image: "https://repository-images.githubusercontent.com/40136600/f3f5fd00-c59e-11e9-8284-cb297d193133" },
            { name: "JUnit", image: "https://upload.wikimedia.org/wikipedia/commons/5/59/JUnit_5_Banner.png" },
            { name: "Strikt", image: "https://strikt.io/assets/images/logo.png" },
            { name: "Mockito", image: "https://raw.githubusercontent.com/mockito/mockito.github.io/master/img/logo%402x.png" },
            { name: "Mockk", image: "https://mockk.io/doc/logo-site.png" },
            { name: "Laravel", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" },
            { name: "React", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" },
            { name: "Vue.js", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1200px-Vue.js_Logo_2.svg.png" },
            { name: "Bootstrap", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" },
            { name: "Tailwind CSS", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" },
            { name: "Symfony", image: "https://symfony.com/logos/symfony_black_02.png" },
            { name: "Flutter", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Google-flutter-logo.png/1200px-Google-flutter-logo.png" },
            { name: "Dart", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Dart-logo.png/1200px-Dart-logo.png" },
        ]
    },
    {
        category: "Database Management Systems",
        items: [
            { name: "PostgreSQL", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1200px-Postgresql_elephant.svg.png" },
            { name: "MySQL", image: "https://upload.wikimedia.org/wikipedia/fr/thumb/6/62/MySQL.svg/1200px-MySQL.svg.png" },
            { name: "SQLite", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/SQLite370.svg/1200px-SQLite370.svg.png" },
            { name: "MongoDB", image: "https://upload.wikimedia.org/wikipedia/fr/thumb/4/45/MongoDB-Logo.svg/1200px-MongoDB-Logo.svg.png" },
            { name: "MariaDB", image: "https://mariadb.com/wp-content/uploads/2019/11/mariadb-logo_black-transparent-300x75.png" },
            { name: "Oracle", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Oracle_logo.svg/1200px-Oracle_logo.svg.png" },
            { name: "Microsoft SQL Server", image: "https://seeklogo.com/images/M/microsoft-sql-server-logo-96AF49E2B3-seeklogo.com.png" },
        ]
    },
    {
        category: "Tools and Technologies",
        items: [
            { name: "Git", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/1200px-Git_icon.svg.png" },
            { name: "GitHub", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1200px-Octicons-mark-github.svg.png" },
            { name: "GitLab", image: "https://images.ctfassets.net/xz1dnu24egyd/1hnQd13UBU7n5V0RsJcbP3/769692e40a6d528e334b84f079c1f577/gitlab-logo-100.png" },
            { name: "Docker", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Docker_%28container_engine%29_logo.svg/1200px-Docker_%28container_engine%29_logo.svg.png" },
            { name: "DockerSwarm", image: "https://aptira.com/wp-content/uploads/2016/09/dockerswarm_logo.png" },
            { name: "Kubernetes", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/1200px-Kubernetes_logo_without_workmark.svg.png" },
            { name: "Youtrack", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/YouTrack_Icon.svg/768px-YouTrack_Icon.svg.png" },
            { name: "IntelliJ IDEA", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/768px-IntelliJ_IDEA_Icon.svg.png" },
            { name: "Visual Studio Code", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" },
            { name: "Visual Studio", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Visual_Studio_Icon_2019.svg/1200px-Visual_Studio_Icon_2019.svg.png" },
            { name: "Gradle", image: "https://upload.wikimedia.org/wikipedia/fr/e/ed/Gradle_Logo.png" },
            { name: "AsciiDoc", image: "https://upload.wikimedia.org/wikipedia/fr/d/d3/AsciiDoc-R-logo-color.svg" },
            { name: "SonarLint", image: "https://seeklogo.com/images/S/sonarlint-icon-logo-0161BCE8AD-seeklogo.com.png?v=638198548050000000" },
        ]
    }
];
export default skills;
