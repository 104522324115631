import React, {useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import './NavBar.css';

function NavBar() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const menuRef = useRef(); // Référence pour le menu

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Gérer les clics en dehors du menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        window.onload = () => {
            // Forcer une mise à jour de l'état pour rafraîchir le rendu
            setIsMenuOpen(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    const handleLinkClick = () => {
        setIsMenuOpen(false); // Ferme le menu lors du clic sur un lien
    };

    return (
        <nav className="navBar">
            <div className="navBrand">Paul REZZONICO</div>
            <button className="hamburger" onClick={toggleMenu}>
                ☰
            </button>
            <ul ref={menuRef} className={`navList ${isMenuOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/" className="navItem" onClick={handleLinkClick}>Home</Link>
                </li>
                <li>
                    <Link to="/about" className="navItem" onClick={handleLinkClick}>About-me</Link>
                </li>
                <li>
                    <Link to="/skills" className="navItem" onClick={handleLinkClick}>Skills</Link>
                </li>
                <li>
                    <Link to="/portfolio" className="navItem" onClick={handleLinkClick}>Projects</Link>
                </li>
                <li>
                    <Link to="/contact" className="navItem contact" onClick={handleLinkClick}>Contact</Link>
                </li>
            </ul>
        </nav>
    );
}

export default NavBar;
