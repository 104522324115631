const projects = [
    {
        id: 1,
        title: "AhriBot",
        description: "AhriBot is a Discord bot based on the League of Legends character Ahri. It offers a variety of features to entertain, inform, and manage community members from a Discord server. AhriBot is not intended for external hosting.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/AhriBot/main/src/main/resources/Logo.png",
        path: "https://github.com/paul-rezzonico/AhriBot",
        technologies: ["Kotlin", "JDA", "Spring", "MongoDB", "Docker"],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 2,
        title: "Latice",
        description: "Latice is a Java-based application showcasing a board game with interactive elements, based on the DxD anime series. It demonstrates object-oriented design, GUI development with JavaFX, and game logic handling.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/latice/main/src/main/resources/Logo.png",
        path: "https://github.com/paul-rezzonico/latice",
        technologies: ["Java 8", "JavaFX", "Maven", "JUnit", "Git", "IntelliJ IDEA"],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 3,
        title: "Truckers",
        description: "Truckers is an Android service that tracks messages from truck drivers related to their routes, appointments... It filters messages from invalid sources, stores them, and synchronizes with a REST API for history tracking on the Admin side.",
        imageUrl: "https://github.com/paul-rezzonico/truckers/blob/main/app/src/main/res/truckers-logo.png?raw=true",
        path: "https://github.com/paul-rezzonico/truckers",
        technologiesUsed: ["Kotlin", "Android"],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 4,
        title: "Truckers API",
        description: "Truckers API is a REST API that allows managing messages sent by truckers. It's designed to work with the Truckers mobile app.",
        imageUrl: "https://github.com/paul-rezzonico/truckers_api/blob/main/Logo.png?raw=true",
        path: "https://github.com/paul-rezzonico/truckers_api",
        technologiesUsed: [
            "Kotlin",
            "Spring",
            "Docker",
            "Junit"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 5,
        title: "Stodolist",
        description: "Stodolist is a simple kotlin-based task management application for Android. It allows users to create, edit, and delete tasks, with reminders and notifications to keep track of deadlines.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/stodolist/main/logo.png",
        path: "https://github.com/paul-rezzonico/stodolist",
        technologiesUsed: [
            "Kotlin",
            "Android",
            "Sqlite",
            "SonarLint",
            "Git",
            "Android Studio"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 6,
        title: "FilmonAhri API",
        description: "FilmonAhri API is a REST API developed in Javascript with Node.js and Hapi.js. It allows management of films and users, including the ability to favorite a film, and manage users and their roles.",
        imageUrl: "https://github.com/paul-rezzonico/filmonahri/blob/main/Logo.png?raw=true",
        path: "https://github.com/paul-rezzonico/filmonahri",
        technologiesUsed: [
            "Node.js",
            "Hapi.js",
            "MySQL",
            "Docker",
            "JWT",
            "Knex",
            "Joi",
            "Nodemailer",
            "Swagger",
            "Eslint",
            "RabbitMQ",
            "Jest"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 7,
        title: "renamTO",
        description: "This project is a Java application that allows to rename all files in a directory, by setting a name and a number of digits.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/renamTO/main/logo.png",
        path: "https://github.com/paul-rezzonico/renamTO",
        technologiesUsed: [
            "Java",
            "Maven"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 8,
        title: "Raymarin App",
        description: "Raymarin is a mobile application developed using Xamarin Forms, designed to interact with the Spotify API. It allows users to search for and display information about artists and albums, showcasing details such as musical genres, follower counts, popularity, and more. This application is just a training to test interaction with Spotify API.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/raymarin/main/Logo.png",
        path: "https://github.com/paul-rezzonico/raymarin",
        technologiesUsed: [
            "C#",
            "Android",
            "IOS",
            "Spotify",
            "Xamarin Forms"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    },
    {
        id: 9,
        title: "Real-Time Project",
        description: "This is the 'Real-Time' project, a Java-based application. The application is a simple real-time application that displays the current time, built using Java and Maven.",
        imageUrl: "https://raw.githubusercontent.com/paul-rezzonico/real-time/main/logo.png",
        path: "https://github.com/paul-rezzonico/real-time",
        technologiesUsed: [
            "Java",
            "Maven"
        ],
        license: "MIT",
        contactLink: "https://github.com/paul-rezzonico#-me-contacter"
    }
];

export default projects;
